import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HubProvider, TdlsProvider } from "src/hooks";
import { store } from "src/redux";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <StrictMode>
    <Provider store={store}>
      <HubProvider>
        <TdlsProvider>
          <App />
        </TdlsProvider>
      </HubProvider>
      <ToastContainer
        position="top-right"
        autoClose={60000}
        toastStyle={{ color: "white", backgroundColor: "#202427" }}
      />
    </Provider>
  </StrictMode>,
);
