import React from "react";
import AircraftList from "src/enums/AircraftList";
import { selectedAircraftIdSelector, selectedAircraftListSelector, useAppSelector } from "src/redux";
import * as S from "src/styles/tdls/dcl";
import { CpdlcAircraftList, DclAircraftList, PdcAircraftList } from "./AircraftLists";
import ClearanceEditor from "./ClearanceEditor";

function Dcl() {
  const aircraftIsSelected = !!useAppSelector(selectedAircraftIdSelector);
  const selectedAircraftList = useAppSelector(selectedAircraftListSelector);

  return (
    <S.Dcl>
      <S.AircraftListHeader $selected={selectedAircraftList === AircraftList.Dcl}>
        <S.LeftArrow />
        DCL
        <S.RightArrow />
      </S.AircraftListHeader>
      <DclAircraftList />
      <S.Cleared>
        <S.PdcList>
          <S.AircraftListHeader $selected={selectedAircraftList === AircraftList.Pdc} $clearedList>
            PDC
          </S.AircraftListHeader>
          <PdcAircraftList />
        </S.PdcList>
        <S.CpdlcList>
          <S.AircraftListHeader $selected={selectedAircraftList === AircraftList.Cpdlc} $clearedList>
            <S.LeftArrow />
            CPDLC
            <S.RightArrow />
          </S.AircraftListHeader>
          <CpdlcAircraftList />
        </S.CpdlcList>
      </S.Cleared>
      {aircraftIsSelected && <ClearanceEditor />}
    </S.Dcl>
  );
}

export default Dcl;
