import React, { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { LoginSidebar } from "src/components/login";
import {
  environmentSelector,
  facilityIdsSelector,
  login,
  nasTokenSelector,
  refreshNasToken,
  setVatsimCode,
  useAppDispatch,
  useAppSelector,
  vatsimTokenSelector,
} from "src/redux";
import * as S from "src/styles/login";
import { DOMAIN } from "src/utils";

function Login() {
  const vatsimToken = useAppSelector(vatsimTokenSelector);
  const nasToken = useAppSelector(nasTokenSelector);
  const environment = useAppSelector(environmentSelector);
  const facilityIds = useAppSelector(facilityIdsSelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const code = useRef(searchParams.get("code"));
  const loginNext = useRef(localStorage.getItem("login-next"));

  useEffect(() => {
    if (code.current && environment) {
      dispatch(setVatsimCode(code.current));
      dispatch(login({ code: code.current, redirectUrl: encodeURIComponent(`${DOMAIN}/login`) }));
      code.current = undefined!;
    }
  }, [environment]);

  useEffect(() => {
    if (code && vatsimToken) {
      navigate("/login", { replace: true });
    }
  }, [vatsimToken]);

  useEffect(() => {
    if (vatsimToken && !nasToken && environment) {
      dispatch(refreshNasToken());
    }
  }, [vatsimToken, nasToken, environment]);

  useEffect(() => {
    if (loginNext.current && facilityIds) {
      const nextFacilityId = loginNext.current.slice(1);
      if (facilityIds.includes(nextFacilityId)) {
        navigate(loginNext.current);
      } else {
        localStorage.removeItem("login-next");
        loginNext.current = undefined!;
        toast.error(`Facility ${nextFacilityId} not available`);
      }
    }
  }, [facilityIds]);

  return (
    <>
      <S.LoginBackground />
      <LoginSidebar />
    </>
  );
}

export default Login;
