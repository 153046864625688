import React, { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ErrorPage, Login, Tdls } from "src/pages";
import { darkModeIsEnabledSelector, getVnasConfiguration, useAppDispatch, useAppSelector } from "src/redux";
import { PrivateRoute } from "src/routes";
import { DARK_MODE, GlobalStyle, LIGHT_MODE } from "src/styles";
import { ThemeProvider } from "styled-components";

function App() {
  const darkModeIsEnabled = useAppSelector(darkModeIsEnabledSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getVnasConfiguration());
  }, []);

  return (
    <ThemeProvider theme={darkModeIsEnabled ? DARK_MODE : LIGHT_MODE}>
      <GlobalStyle />
      <ErrorBoundary fallbackRender={ErrorPage}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/:id" element={<Tdls />} />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
