export const DARK_MODE = {
  background: "black",
  color: "white",
  secondaryColor: "#b7b7b7",
};

export const LIGHT_MODE = {
  background: "white",
  color: "black",
  secondaryColor: "lightgrey",
};
