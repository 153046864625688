import React from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { overrideDepFreqSelector, setOverrideDepFreq, temporaryDepFreqsSelector } from "src/redux/slices/tdlsSlice";
import * as S from "src/styles/menus";

function OverrideFrequencyInput() {
  const dispatch = useAppDispatch();
  const overrideDepFreq = useAppSelector(overrideDepFreqSelector);
  const temporaryDepFreqs = useAppSelector(temporaryDepFreqsSelector);

  return (
    <>
      <S.Subtitle>Override Dep Freq:</S.Subtitle>
      <S.OverrideFreqSelect
        key={overrideDepFreq}
        defaultValue={overrideDepFreq ?? "-1"}
        onChange={(e) => dispatch(setOverrideDepFreq(e.target.value === "-1" ? undefined : e.target.value))}
      >
        <option value="-1">None</option>
        {temporaryDepFreqs.map((f) => (
          <option value={f} key={f}>
            {f}
          </option>
        ))}
      </S.OverrideFreqSelect>
    </>
  );
}

export default OverrideFrequencyInput;
