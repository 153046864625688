import { styled } from "styled-components";

export const Header = styled.div`
  width: 100%;
  background-color: #000000;
  display: flex;
  justify-content: space-between;
`;

export const LeftHeader = styled.div`
  background-color: #d3d3d3;
  border: 3px solid #adadad;
  box-shadow: inset 1px 1px 3px #000;
  padding: 2px 8px 1px 8px;
  display: flex;
  align-items: center;
`;

export const LeftHeaderItem = styled.div<{ $selected?: boolean }>`
  font-family: "CourierPrime";
  color: black;
  padding: 0;
  font-size: 16pt;
  border: 2px solid;
  border-color: #00c400 #003700 #003700 #00c400;
  background-color: #11ee11;
  margin: 3px 6px;
  pointer-events: none;
  font-weight: bold;
  white-space: pre;

  ${(props) => props.$selected && { border: "3px solid #000000", cursor: "pointer", pointerEvents: "all" }}
`;

export const PaddedLeftHeaderItem = styled(LeftHeaderItem)`
  padding: 2px 10px;
`;

export const RightHeader = styled.div`
  background-color: #ffffff;
  border: 3px solid #80ee80;
  padding: 0 8px;
  height: min-content;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: clip;

  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;

export const RightHeaderItemBox = styled.div<{ $color: string }>`
  margin: 0 12px;
  padding: 1px 3px;
  border: 3px solid ${(p) => p.$color};
  display: flex;
`;

export const RightHeaderItemLabel = styled.div<{ $color: string; $active?: boolean }>`
  font-family: "CourierPrime";
  font-size: 16pt;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  width: 80px;
  height: 24px;
  margin-left: 8px;
  margin-right: 4px;
  border: 3px solid ${(p) => p.$color};
  color: ${(p) => (p.$active ? p.$color : "white")};
  background-color: ${(p) => (p.$active ? "white" : p.$color)};
`;

export const RightHeaderItemIndicator = styled.div<{ $color: string; $active?: boolean }>`
  width: 20px;
  height: 24px;
  margin-left: 10px;
  border: 3px solid ${(p) => p.$color};
  ${(p) => !p.$active && { "background-color": p.$color }};
`;
