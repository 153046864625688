import React, { useEffect, useState } from "react";
import * as S from "src/styles/tdls/footer";

const zeroPad = (n: number) => String(n).padStart(2, "0");

function Clock() {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setDate(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <S.Clock>
      {zeroPad(date.getUTCHours())}:{zeroPad(date.getUTCMinutes())}
    </S.Clock>
  );
}

export default Clock;
