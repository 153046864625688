import { TdlsConfiguration, TdlsSidTransition } from "@vatsim-vnas/js-libs/models/facilities";
import { CLEARANCE_FIELD_TAB_ORDER, Clearance, ClearanceField } from "@vatsim-vnas/js-libs/models/vnas/flight-data";
import { cycleNextItemInArray, cyclePreviousItemInArray, findOrError, randomNumber } from "@vatsim-vnas/js-libs/utils";
import { instanceToInstance } from "class-transformer";
import React, { ReactNode, createContext, useContext, useMemo } from "react";
import { toast } from "react-toastify";
import { AIRCRAFT_LIST_TAB_ORDER, AircraftList, ClearanceStatus } from "src/enums";
import { useHub } from "src/hooks";
import { TdlsFlightPlan } from "src/models";
import {
  clearanceSelector,
  deleteFlightPlan as deleteFlightPlanInState,
  flightPlansSelector,
  highlightedAircraftIdSelector,
  overrideDepFreqSelector,
  selectedAircraftIdSelector,
  selectedAircraftListSelector,
  selectedClearanceFieldSelector,
  setClearance as setClearanceInState,
  setClearanceStatus as setClearanceStatusInState,
  setHighlightedAircraftId as setHighlightedAircraftIdInState,
  setSelectedAircraftId as setSelectedAircraftIdInState,
  setSelectedAircraftList as setSelectedAircraftListInState,
  setSelectedClearanceField,
  setSelectedClearanceField as setSelectedClearanceFieldInState,
  tdlsConfigurationsSelector,
  useAppDispatch,
  useAppSelector,
  visibleFacilityIdsSelector,
} from "src/redux";
import { debugLog } from "src/utils";

interface Tdls {
  cycleHighlightedAircraft: (next: boolean) => void;
  cycleSelectedClearanceField: (next: boolean) => void;
  cycleSelectedList: (next: boolean) => void;
  dumpFlightPlan: (aircraftId: string) => void;
  getCurrentConfiguration: () => TdlsConfiguration;
  getFlightPlan: (aircraftId: string) => TdlsFlightPlan;
  getFlightPlansInList: (list: AircraftList) => TdlsFlightPlan[];
  getTransitions: (sidName: string | undefined) => TdlsSidTransition[];
  selectAircraft: (aircraftId: string) => void;
  sendClearance: (aircraftId: string, clearance: Clearance) => void;
  updateClearance: (field: ClearanceField, value: string) => void;
}

const TdlsContext = createContext<Tdls>(undefined!);

interface TdlsProviderProps {
  children: ReactNode;
}

export function TdlsProvider({ children }: Readonly<TdlsProviderProps>) {
  const tdlsConfigurations = useAppSelector(tdlsConfigurationsSelector);
  const visibleFacilityIds = useAppSelector(visibleFacilityIdsSelector);
  const flightPlans = useAppSelector(flightPlansSelector);
  const selectedAircraftList = useAppSelector(selectedAircraftListSelector);
  const highlightedAircraftId = useAppSelector(highlightedAircraftIdSelector);
  const selectedAircraftId = useAppSelector(selectedAircraftIdSelector);
  const selectedClearanceField = useAppSelector(selectedClearanceFieldSelector);
  const clearance = useAppSelector(clearanceSelector);
  const overrideDepFreq = useAppSelector(overrideDepFreqSelector);
  const dispatch = useAppDispatch();
  const hub = useHub();

  async function invokeHubMethod(methodName: string, successMessage: string, errorMessage: string, ...args: unknown[]) {
    try {
      await hub.invoke(methodName, ...args);
      debugLog(successMessage);
    } catch (e) {
      debugLog(errorMessage);
      toast.error(`${errorMessage}: ${e}`);
    }
  }

  function getFlightPlan(aircraftId: string) {
    return findOrError(flightPlans, (f) => f.aircraftId === aircraftId, `Failed to find flight plan for ${aircraftId}`);
  }

  function getFlightPlansInList(list: AircraftList) {
    switch (list) {
      case AircraftList.Dcl:
        return flightPlans.filter(
          (f) => f.tdlsFacility && !f.clearance && !f.tdlsDumped && visibleFacilityIds.includes(f.tdlsFacility),
        );
      case AircraftList.Pdc:
        return flightPlans.filter(
          (f) => f.tdlsFacility && f.clearance && !f.tdlsDumped && visibleFacilityIds.includes(f.tdlsFacility),
        );
      case AircraftList.Cpdlc:
      default:
        return [];
    }
  }

  function getAircraftsList(aircraftId: string) {
    const flightPlan = getFlightPlan(aircraftId);
    if (flightPlan.tdlsDumped) {
      return undefined;
    }
    if (flightPlan.clearance) {
      return AircraftList.Pdc;
    }
    return AircraftList.Dcl;
  }

  function selectAircraft(aircraftId: string) {
    dispatch(setHighlightedAircraftIdInState(aircraftId));
    dispatch(setSelectedClearanceFieldInState(ClearanceField.Sid));
    dispatch(setSelectedAircraftListInState(getAircraftsList(aircraftId)!));
    const flightPlan = getFlightPlan(aircraftId);
    if (flightPlan.clearance) {
      dispatch(setClearanceInState(flightPlan.clearance));
    } else {
      const newClearance = new Clearance();

      if (!flightPlan.tdlsFacility) {
        throw Error("Flight plan TDLS facility is undefined");
      }

      const tdlsConfiguration = tdlsConfigurations.get(flightPlan.tdlsFacility);

      if (!tdlsConfiguration) {
        throw Error(`TDLS configuration for ${flightPlan.tdlsFacility} not found`);
      }

      newClearance.populateFromRoute(tdlsConfiguration, flightPlan.route, overrideDepFreq);
      dispatch(setClearanceInState(newClearance));
      updateClearanceStatus(newClearance, tdlsConfiguration);
    }
    dispatch(setSelectedAircraftIdInState(aircraftId));
  }

  function highlightNextAircraft(skipHighlightedAircraft: boolean, list?: AircraftList) {
    const listFlightPlans = getFlightPlansInList(list ?? selectedAircraftList);
    if (!listFlightPlans.length || (listFlightPlans.length === 1 && skipHighlightedAircraft)) {
      dispatch(setHighlightedAircraftIdInState(undefined));
    } else if (!highlightedAircraftId) {
      dispatch(setHighlightedAircraftIdInState(listFlightPlans[0].aircraftId));
    } else {
      const currentIndex = listFlightPlans.findIndex((f) => f.aircraftId === highlightedAircraftId);
      dispatch(setHighlightedAircraftIdInState(cycleNextItemInArray(listFlightPlans, currentIndex).aircraftId));
    }
  }

  function selectList(list: AircraftList) {
    dispatch(setSelectedAircraftListInState(list));
    highlightNextAircraft(false, list);
  }

  function cycleSelectedList(next: boolean) {
    const currentIndex = AIRCRAFT_LIST_TAB_ORDER.findIndex((l) => l === selectedAircraftList);
    if (next) {
      selectList(cycleNextItemInArray(AIRCRAFT_LIST_TAB_ORDER, currentIndex));
    } else {
      selectList(cyclePreviousItemInArray(AIRCRAFT_LIST_TAB_ORDER, currentIndex));
    }
  }

  function cycleHighlightedAircraft(next: boolean) {
    const listFlightPlans = getFlightPlansInList(selectedAircraftList);
    if (!listFlightPlans.length) {
      return;
    }
    const currentIndex = listFlightPlans.findIndex((f) => f.aircraftId === highlightedAircraftId);
    if (next) {
      dispatch(setHighlightedAircraftIdInState(cycleNextItemInArray(listFlightPlans, currentIndex).aircraftId));
    } else {
      dispatch(setHighlightedAircraftIdInState(cyclePreviousItemInArray(listFlightPlans, currentIndex).aircraftId));
    }
  }

  function cycleSelectedClearanceField(next: boolean) {
    const currentIndex = CLEARANCE_FIELD_TAB_ORDER.findIndex((f) => f === selectedClearanceField);
    if (next) {
      dispatch(setSelectedClearanceField(cycleNextItemInArray(CLEARANCE_FIELD_TAB_ORDER, currentIndex)));
    } else {
      dispatch(setSelectedClearanceField(cyclePreviousItemInArray(CLEARANCE_FIELD_TAB_ORDER, currentIndex)));
    }
  }

  function updateClearanceStatus(newClearance: Clearance, configuration: TdlsConfiguration) {
    if (newClearance.isValid(configuration)) {
      dispatch(setClearanceStatusInState(ClearanceStatus.PdcOk));
    } else {
      dispatch(setClearanceStatusInState(ClearanceStatus.MandatoryFieldNotSet));
    }
  }

  function getSid(sidName: string) {
    const configuration = getSelectedAircraftsTdlsConfiguration();
    return findOrError(configuration.sids, (s) => s.name === sidName, `SID ${sidName} not found`);
  }

  function getTransitions(sidName: string | undefined) {
    if (sidName === undefined) {
      return [];
    }
    return getSid(sidName).transitions;
  }

  function getSelectedAircraftsTdlsConfiguration() {
    if (!selectedAircraftId) {
      throw Error("Selected aircraft is undefined");
    }

    const facility = getFlightPlan(selectedAircraftId).tdlsFacility;
    if (!facility) {
      throw Error("Selected aircraft's TDLS facility is undefined");
    }

    const configuration = tdlsConfigurations.get(facility);
    if (!configuration) {
      throw Error(`TDLS configuration for ${facility} not found`);
    }

    return configuration;
  }

  function updateClearance(field: ClearanceField, value: string) {
    const newClearance = instanceToInstance(clearance!);
    const configuration = getSelectedAircraftsTdlsConfiguration();

    newClearance.setField(field, value === "-1" ? undefined : value);
    if (field === ClearanceField.Sid) {
      if (value === "-1") {
        newClearance.setField(ClearanceField.Transition, value === "-1" ? undefined : value);
      } else {
        newClearance.populateFromSid(getSid(value), undefined, overrideDepFreq);
      }
    } else if (field === ClearanceField.Transition && value !== "-1") {
      const sid = getSid(newClearance.sid!);
      const transition = getTransitions(newClearance.sid).find((t) => t.name === value)!;
      newClearance.populateFromSid(sid, transition, overrideDepFreq);
    } else if (field === ClearanceField.Climbvia && value !== "-1") {
      newClearance.setField(ClearanceField.InitialAlt, undefined);
    } else if (field === ClearanceField.InitialAlt && value !== "-1") {
      newClearance.setField(ClearanceField.Climbvia, undefined);
    }
    dispatch(setClearanceInState(newClearance));
    updateClearanceStatus(newClearance, configuration);
  }

  function sendClearance(aircraftId: string, newClearance: Clearance) {
    highlightNextAircraft(true);
    dispatch(deleteFlightPlanInState(aircraftId));
    dispatch(setSelectedAircraftIdInState(undefined));
    dispatch(setClearanceStatusInState(undefined));
    setTimeout(
      () => {
        invokeHubMethod(
          "SendClearance",
          `Sent clearance to ${aircraftId}`,
          `Error sending PDC`,
          aircraftId,
          newClearance,
        );
      },
      randomNumber(1000, 5000),
    );
  }

  function dumpFlightPlan(aircraftId: string) {
    highlightNextAircraft(true);
    dispatch(setSelectedAircraftIdInState(undefined));
    dispatch(setClearanceStatusInState(undefined));
    invokeHubMethod("TdlsDump", `Dumped ${aircraftId}`, "Error dumping flight plan", aircraftId);
  }

  const functions = useMemo(
    () => ({
      cycleHighlightedAircraft,
      cycleSelectedClearanceField,
      cycleSelectedList,
      dumpFlightPlan,
      getCurrentConfiguration: getSelectedAircraftsTdlsConfiguration,
      getFlightPlan,
      getFlightPlansInList,
      getTransitions,
      selectAircraft,
      sendClearance,
      updateClearance,
    }),
    [
      cycleHighlightedAircraft,
      cycleSelectedClearanceField,
      cycleSelectedList,
      dumpFlightPlan,
      getSelectedAircraftsTdlsConfiguration,
      getFlightPlan,
      getFlightPlansInList,
      getTransitions,
      selectAircraft,
      sendClearance,
      updateClearance,
    ],
  );

  return <TdlsContext.Provider value={functions}>{children}</TdlsContext.Provider>;
}

function useTdls() {
  return useContext(TdlsContext);
}

export default useTdls;
