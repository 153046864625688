import { ClearanceField } from "@vatsim-vnas/js-libs/models/vnas/flight-data";
import styled, { css } from "styled-components";

export const FlightPlan = styled.div`
  font-family: "Consolas";
  position: fixed;
  height: 300px;
  width: calc(100vw - 75px);
  bottom: calc(20px + env(safe-area-inset-bottom));
  left: 50%;
  border: 1px solid;
  background-color: ${(p) => p.theme.background};
  border-color: ${(p) => `${p.theme.color} ${p.theme.color} ${p.theme.background}`};
  transform: translate(-50%);
  padding: 20px 5px;
  z-index: 10;
`;

export const FlightPlanRow = styled.div`
  min-height: 20px;
  display: flex;
  margin-bottom: 5px;
  overflow-x: hidden;
`;

export const FlightPlanRow1 = styled(FlightPlanRow)`
  min-height: 50px;
`;

export const FlightPlanRow2 = styled(FlightPlanRow)`
  justify-content: space-between;
`;

const FlightPlanCol = styled.div`
  display: inline-flex;
  justify-content: space-between;
  overflow-x: hidden;
`;

export const FlightPlanCol1 = styled(FlightPlanCol)`
  width: 15%;
  min-width: fit-content;
`;

export const FlightPlanCol2 = styled(FlightPlanCol)`
  margin: 0 10px;
  width: 85%;
  white-space: normal;
  flex-wrap: wrap;
`;

export const FlightPlanRoute = styled(FlightPlanCol2)`
  justify-content: left;
`;

export const FlightPlanCol3 = styled(FlightPlanCol)`
  width: 40%;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
`;

export const FlightPlanCol4 = styled(FlightPlanCol)`
  width: 85%;
  margin-left: 10px;
`;

export const FlightPlanField = styled.span<{ $leftMargin?: boolean }>`
  ${(p) =>
    p.$leftMargin &&
    css`
      margin-left: 5px;
    `}
`;

export const FlightPlanFooter = styled(FlightPlanCol)`
  position: absolute;
  bottom: 0;
  margin-bottom: 5px;
  width: calc(100% - 15px);
  display: flex;
  justify-content: end;
`;

export const FlightPlanButton = styled.button<{ $isOnLeft?: boolean; $isHidden?: boolean }>`
  border: 1px solid #767676;
  margin-left: 10px;
  bottom: 0;
  padding: 2px 0;
  text-align: center;
  border-radius: 4px;
  width: 100px;
  visibility: ${(p) => p.$isHidden && "hidden"};

  ${(p) =>
    p.$isOnLeft &&
    css`
      position: absolute;
      left: 100px;
    `};

  &:disabled {
    color: ${(p) => p.theme.secondaryColor};
  }

  &:hover:not(:disabled) {
    cursor: pointer;
  }

  &:active:not(:disabled) {
    background-color: lightgrey;
  }
`;

export const ClearanceFieldSelect = styled.select<{ $field: ClearanceField }>`
  border-radius: 0;
  display: inline-flex;
  vertical-align: top;
  border: 1px solid #767676;
  height: 24px;
  font-family: "VRC";
  border-radius: 4px !important;

  ${(p) => {
    switch (p.$field) {
      case ClearanceField.Sid:
        return css`
          margin-right: 5px;
          flex-grow: 1;
        `;
      case ClearanceField.Transition:
        return css`
          margin-left: 5px;
          flex-grow: 1;
        `;
      case ClearanceField.Expect:
        return css`
          width: 200px;
        `;
      case ClearanceField.Climbout:
        return css`
          flex-grow: 3;
          margin-right: 5px;
        `;
      case ClearanceField.Climbvia:
        return css`
          flex-grow: 3;
          margin-right: 5px;
          margin-left: 5px;
        `;
      case ClearanceField.InitialAlt:
        return css`
          flex-grow: 1;
          margin-left: 5px;
        `;
      case ClearanceField.ContactInfo:
      case ClearanceField.LocalInfo:
        return css`
          width: 25%;
        `;
      case ClearanceField.DepFreq:
        return css`
          margin-left: 10px;
          width: 150px;
        `;
      default:
        return undefined;
    }
  }}
`;
