import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { frequencyToString } from "@vatsim-vnas/js-libs/utils";
import React, { useEffect } from "react";
import { CurrentLogin, FacilitiesTable } from "src/components/login";
import { Checkbox, LogoutButton } from "src/components/ui";
import { useKey } from "src/hooks";
import {
  addCurrentFreqToTempDepFreqsSelector,
  darkModeIsEnabledSelector,
  facilityFilterIsEnabledSelector,
  facilityIdsSelector,
  facilityMenuIsActiveSelector,
  notificationSoundIsEnabledSelector,
  sessionSelector,
  setAddCurrentFreqToTempDepFreqs,
  setDarkModeIsEnabled,
  setFacilityFilterIsEnabled,
  setFacilityMenuIsActive,
  setNotificationSoundIsEnabled,
  temporaryDepFreqsSelector,
  useAppDispatch,
  useAppSelector,
} from "src/redux";
import { setTemporaryDepFreqs } from "src/redux/slices/tdlsSlice";
import * as S from "src/styles/menus";
import { Hr, VersionNumber } from "src/styles/ui";
import { VERSION } from "src/utils";
import FrequenciesInput from "./FrequenciesInput";
import OverrideFrequencyInput from "./OverrideFrequencyInput";

function FacilityMenu() {
  const session = useAppSelector(sessionSelector);
  const primaryPosition = session?.getPrimaryPosition();
  const facilityMenuActive = useAppSelector(facilityMenuIsActiveSelector);
  const facilityIds = useAppSelector(facilityIdsSelector);
  const temporaryDepFreqs = useAppSelector(temporaryDepFreqsSelector);
  const addCurrentFreqToTempDepFreqs = useAppSelector(addCurrentFreqToTempDepFreqsSelector);
  const filterEnabled = useAppSelector(facilityFilterIsEnabledSelector);
  const darkModeEnabled = useAppSelector(darkModeIsEnabledSelector);
  const notificationSoundEnabled = useAppSelector(notificationSoundIsEnabledSelector);
  const dispatch = useAppDispatch();

  useKey("Escape", () => dispatch(setFacilityMenuIsActive(false)), { facilityMenuIsActive: true });

  useEffect(() => {
    if (!addCurrentFreqToTempDepFreqs || !primaryPosition) {
      return;
    }

    const currentFrequency = frequencyToString(primaryPosition.frequency, false);

    if (!currentFrequency) {
      return;
    }

    if (temporaryDepFreqs.includes(currentFrequency)) {
      return;
    }

    dispatch(setTemporaryDepFreqs([currentFrequency, ...temporaryDepFreqs]));
  }, [addCurrentFreqToTempDepFreqs]);

  if (!facilityMenuActive) {
    return undefined;
  }

  return (
    <>
      <S.Screen onClick={() => dispatch(setFacilityMenuIsActive(false))} />
      <S.FacilityMenu>
        <S.CloseButton onClick={() => dispatch(setFacilityMenuIsActive(false))}>
          <FontAwesomeIcon icon={faTimes} />
        </S.CloseButton>
        <S.FacilityMenuContent>
          <CurrentLogin showSessionStatus showHiddenFacilities />
          <Hr />
          <FrequenciesInput />
          <Hr />
          <OverrideFrequencyInput />
          <Hr />
          {facilityIds && facilityIds.length > 1 && (
            <>
              <S.Subtitle>Change Facility:</S.Subtitle>
              <FacilitiesTable onSelectFacility={() => dispatch(setFacilityMenuIsActive(false))} />
            </>
          )}
          <Checkbox
            label="Add current frequency to Temp. Dep Freqs"
            checked={addCurrentFreqToTempDepFreqs}
            onChange={(checked) => dispatch(setAddCurrentFreqToTempDepFreqs(checked))}
          />
          <Checkbox
            label="Filter staffed underlying facilities"
            checked={filterEnabled}
            onChange={(checked) => dispatch(setFacilityFilterIsEnabled(checked))}
          />
          <Checkbox
            label="Enable dark mode"
            checked={darkModeEnabled}
            onChange={(checked) => dispatch(setDarkModeIsEnabled(checked))}
          />
          <Checkbox
            label="Play notification sound"
            checked={notificationSoundEnabled}
            onChange={(checked) => dispatch(setNotificationSoundIsEnabled(checked))}
          />
          <br />
          <LogoutButton />
          <S.Version>
            <b>Version</b>
            <VersionNumber>{VERSION}</VersionNumber>
          </S.Version>
        </S.FacilityMenuContent>
      </S.FacilityMenu>
    </>
  );
}

export default FacilityMenu;
