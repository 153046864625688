import { css, styled } from "styled-components";

export const Dcl = styled.div`
  height: calc(100vh - 56px);
  font-family: "VRC", monospace;
`;

export const AircraftListHeader = styled.div<{ $selected: boolean; $clearedList?: boolean }>`
  height: 26px;
  font-family: "RobotoMono";
  background-color: ${(p) => (p.$selected ? "#11be11" : "#80ee80")};
  border: 2px solid;
  border-color: #afeeaf #4e8b4e #4e8b4e #afeeaf;
  text-align: center;
  position: relative;
  color: black;
  font-weight: ${(p) => (p.$selected ? "bolder" : "normal")};
  font-size: 16px;
  margin: ${(p) => p.$clearedList && "2px"};
`;

const Arrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
`;

export const LeftArrow = styled(Arrow)`
  border-right: 18px solid #9c9c9c;
`;

export const RightArrow = styled(Arrow)`
  right: 0;
  top: 0;
  border-left: 18px solid #9c9c9c;
`;

export const Cleared = styled.div`
  display: flex;
  height: calc(100% - 202px);
`;

export const PdcList = styled.div`
  width: 40%;
  height: 100%;

  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const CpdlcList = styled.div`
  width: 60%;
  height: 100%;

  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

export const AircraftList = styled.div`
  font-size: 11pt;
  margin: 2px 2px;
  border: 2px solid #cacdd2;
  display: flex;
  flex-flow: column wrap;
  align-content: flex-start;
  overflow-x: auto;
  height: 135px;
`;

export const ClearedAircraftList = styled(AircraftList)`
  height: 100%;
`;

export const AircraftRow = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const SelectedIndicator = styled.div<{ $isSelected: boolean }>`
  text-align: left;
  padding: 0 8px;
  width: 25px;
  visibility: ${(p) => !p.$isSelected && "hidden"};
`;

export const AircraftId = styled(AircraftRow)<{ $isHighlighted: boolean; $aircraftIsSelected: boolean }>`
  width: 150px;

  ${(props) =>
    props.$isHighlighted &&
    css`
      background-color: ${(p) => p.theme.color};
      color: ${(p) => p.theme.background};
    `}

  &:hover {
    cursor: ${(p) => !p.$aircraftIsSelected && "pointer"};
  }
`;
