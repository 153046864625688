import React from "react";
import { useTdls } from "src/hooks";
import { highlightedAircraftIdSelector, selectedAircraftIdSelector, useAppSelector } from "src/redux";
import * as S from "src/styles/tdls/dcl";

interface AircraftRowProps {
  aircraftId: string;
}

function AircraftRow({ aircraftId }: Readonly<AircraftRowProps>) {
  const { selectAircraft } = useTdls();
  const selectedAircraftId = useAppSelector(selectedAircraftIdSelector);
  const isHighlighted = useAppSelector(highlightedAircraftIdSelector) === aircraftId;

  return (
    <S.AircraftRow>
      <S.SelectedIndicator $isSelected={selectedAircraftId === aircraftId}>{">"}</S.SelectedIndicator>
      <S.AircraftId
        onClick={() => !selectedAircraftId && selectAircraft(aircraftId)}
        $isHighlighted={isHighlighted}
        $aircraftIsSelected={!!selectedAircraftId}
      >
        {aircraftId}
      </S.AircraftId>
    </S.AircraftRow>
  );
}

export default AircraftRow;
