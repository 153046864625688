import React, { useEffect, useRef } from "react";
import { AircraftList } from "src/enums";
import { useTdls } from "src/hooks";
import { facilityIdSelector, notificationSoundIsEnabledSelector, useAppSelector } from "src/redux";
import * as S from "src/styles/tdls/dcl";
import AircraftRow from "./AircraftRow";

const soundAudio = new Audio(`/sounds/notification.mp3`);

export function DclAircraftList() {
  const facilityId = useAppSelector(facilityIdSelector);
  const soundEnabled = useAppSelector(notificationSoundIsEnabledSelector);
  const { getFlightPlansInList } = useTdls();
  const dclCountRef = useRef<number>();
  const flightPlans = getFlightPlansInList(AircraftList.Dcl);

  useEffect(() => {
    dclCountRef.current = undefined;
  }, [facilityId]);

  useEffect(() => {
    const dclCount = flightPlans.length;
    if (soundEnabled && dclCountRef.current !== undefined && dclCount > dclCountRef.current) {
      soundAudio.play();
    }
    dclCountRef.current = dclCount;
    if (dclCount > 0) {
      document.title = `(${dclCount}) vTDLS | ${facilityId}`;
    } else {
      document.title = `vTDLS | ${facilityId}`;
    }
  }, [flightPlans]);

  return (
    <S.AircraftList>
      {flightPlans.map((f) => (
        <AircraftRow key={f.aircraftId} aircraftId={f.aircraftId} />
      ))}
    </S.AircraftList>
  );
}

export function PdcAircraftList() {
  const { getFlightPlansInList } = useTdls();
  const flightPlans = getFlightPlansInList(AircraftList.Pdc);
  return (
    <S.ClearedAircraftList>
      {flightPlans.map((f) => (
        <AircraftRow key={f.aircraftId} aircraftId={f.aircraftId} />
      ))}
    </S.ClearedAircraftList>
  );
}

export function CpdlcAircraftList() {
  return <S.ClearedAircraftList />;
}
