import { createGlobalStyle } from "styled-components";
import "../fonts/fonts.css";
import "./imports.css";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    background-color: ${(p) => p.theme.background};
    color: ${(p) => p.theme.color}; 
    overflow-y: hidden;
  }

  button, select {
    background-color: ${(p) => p.theme.background};
    color: ${(p) => p.theme.color};
  }
`;

export default GlobalStyle;
