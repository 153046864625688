import React from "react";
import { clearanceStatusSelector, useAppSelector } from "src/redux";
import * as S from "src/styles/tdls/footer";
import Clock from "./Clock";

function Footer() {
  const clearanceStatus = useAppSelector(clearanceStatusSelector);

  return (
    <S.Footer>
      <Clock />
      <S.FooterStatus>CPDLC AUTO: OFF</S.FooterStatus>
      <S.FooterStatus>PDC AUTO: OFF</S.FooterStatus>
      <S.FooterStatus>CAF: ON</S.FooterStatus>
      <S.FooterStatus>PDC ADR SUPR: ALWAYS</S.FooterStatus>
      <S.LeftFooterStatus>{clearanceStatus}</S.LeftFooterStatus>
    </S.Footer>
  );
}

export default Footer;
