import { distinct } from "@vatsim-vnas/js-libs/utils";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { setTemporaryDepFreqs, temporaryDepFreqsSelector } from "src/redux/slices/tdlsSlice";
import * as S from "src/styles/menus";

function FrequenciesInput() {
  const dispatch = useAppDispatch();
  const temporaryDepFreqs = useAppSelector(temporaryDepFreqsSelector);
  const [isInvalid, setIsInvalid] = useState(false);

  const handleSetFrequencies = (rawFrequencies: string) => {
    let frequencies = rawFrequencies
      .toUpperCase()
      .split(/[\s,]+/)
      .filter((s) => s);

    const invalid = frequencies.some((f) => !/^[A-Z0-9., ]{1,7}$/.exec(f));

    setIsInvalid(invalid);
    if (invalid) {
      dispatch(setTemporaryDepFreqs([]));
      return [];
    }

    frequencies = frequencies.filter(distinct);
    dispatch(setTemporaryDepFreqs(frequencies));
    return frequencies;
  };

  return (
    <>
      <S.Subtitle>Temp. Dep Freqs:</S.Subtitle>
      <S.FrequenciesInput
        $invalid={isInvalid}
        key={temporaryDepFreqs.join(", ")}
        defaultValue={temporaryDepFreqs.join(", ")}
        placeholder="134.7, 123.75"
        onBlur={(e) => {
          e.target.value = handleSetFrequencies(e.target.value).join(", ");
        }}
      />
      {isInvalid && <S.InvalidLabel>Invalid Frequency</S.InvalidLabel>}
    </>
  );
}

export default FrequenciesInput;
