import React from "react";
import { LogoutButton } from "src/components/ui";
import { sessionSelector, useAppSelector } from "src/redux";
import * as S from "src/styles/ui";
import CurrentLogin from "./CurrentLogin";
import FacilitiesTable from "./FacilitiesTable";

function FacilitySelect() {
  const sessionIsActive = !!useAppSelector(sessionSelector);

  return (
    <>
      <CurrentLogin />
      <S.Hr />
      {sessionIsActive && (
        <>
          <S.Subtitle>Select Facility:</S.Subtitle>
          <FacilitiesTable />
        </>
      )}
      <LogoutButton />
    </>
  );
}

export default FacilitySelect;
