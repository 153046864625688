import { styled } from "styled-components";

export const Footer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row-reverse;
  font-family: "RobotoMono";
  bottom: 0;
  font-size: 12px;
  width: 100vw;
  height: 20px;
  background-color: ${(p) => p.theme.background};
  padding-bottom: env(safe-area-inset-bottom);
`;

export const FooterStatus = styled.span`
  padding-left: 25px;
  white-space: nowrap;

  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

export const LeftFooterStatus = styled.span`
  position: absolute;
  left: 0;
`;

export const Clock = styled.span`
  font-family: "RobotoMono";
  padding: 0 10px 0 25px;
`;
