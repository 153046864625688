import React, { ReactNode } from "react";
import { facilityIdSelector, setFacilityMenuIsActive } from "src/redux";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import * as S from "src/styles/tdls/header";

interface RightHeaderItemProps {
  active?: boolean;
  children: ReactNode;
  color: string;
  secondaryActive?: boolean;
}

function RightHeaderItem({ active = false, children, color, secondaryActive = false }: Readonly<RightHeaderItemProps>) {
  return (
    <S.RightHeaderItemBox $color={color}>
      <S.RightHeaderItemLabel $color={color} $active={secondaryActive}>
        {children}
      </S.RightHeaderItemLabel>
      <S.RightHeaderItemIndicator $color={color} $active={active} />
    </S.RightHeaderItemBox>
  );
}

function Header() {
  const facilityId = useAppSelector(facilityIdSelector);
  const dispatch = useAppDispatch();

  return (
    <S.Header>
      <S.LeftHeader>
        <S.LeftHeaderItem $selected onClick={() => dispatch(setFacilityMenuIsActive(true))}>
          {facilityId?.toLowerCase() ?? "   "}a
        </S.LeftHeaderItem>
        <S.LeftHeaderItem>{facilityId?.toLowerCase() ?? "   "}b</S.LeftHeaderItem>
        <S.PaddedLeftHeaderItem>TIMS</S.PaddedLeftHeaderItem>
        <S.PaddedLeftHeaderItem>ERAM</S.PaddedLeftHeaderItem>
        <S.PaddedLeftHeaderItem>CPDLC</S.PaddedLeftHeaderItem>
      </S.LeftHeader>
      <S.RightHeader>
        <RightHeaderItem color="#1111EE">FDIO</RightHeaderItem>
        <RightHeaderItem color="black" active>
          DCL
        </RightHeaderItem>
        <RightHeaderItem color="#C311C3">DATIS</RightHeaderItem>
      </S.RightHeader>
    </S.Header>
  );
}

export default Header;
